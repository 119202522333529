:root {
  --color-stripe-height: 3rem;
  --group-gap: 1rem;
  --input-height: 3rem;

  --color-promoter: green;
  --color-passive: yellow;
  --color-detractor: red;

  --color-contrast-text: white;
}

@media (max-width: 600px) {
  :root {
    --color-stripe-height: 2rem;
    --group-gap: 0.5rem;
    --input-height: 2.5rem;
  }
}

.page {
  max-width: 60rem;
  margin: auto;
  padding: 0.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-and-explanation-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
}

.input-groups-wrapper {
  display: flex;
  gap: var(--group-gap);
  flex-wrap: wrap;
  justify-content: center;
}

.input-groups-pas-prom {
  display: flex;
  gap: var(--group-gap);
  flex-wrap: wrap;
  justify-content: center;
}

.nps-score-group-wrapper {
  padding: var(--group-gap);
  display: flex;
  gap: var(--group-gap);
  flex-direction: column;
  position: relative;
  z-index: 0;
  padding-bottom: calc(var(--color-stripe-height) + var(--group-gap));
}

.nps-score-group-wrapper::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--color-stripe-height);
  z-index: -1;
}

.nps-score-group-wrapper[data-nps-type='promoter']::before {
  background-color: var(--color-promoter);
}

.nps-score-group-wrapper[data-nps-type='passive']::before {
  background-color: var(--color-passive);
}

.nps-score-group-wrapper[data-nps-type='detractor']::before {
  background-color: var(--color-detractor);
}

.inputs-wrapper {
  display: flex;
  gap: var(--group-gap);
  flex-wrap: wrap;
  justify-content: center;
}

.group-description {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  height: var(--color-stripe-height);
  text-align: center;
  font-size: calc(var(--color-stripe-height) * 0.4);
  z-index: 1;
  color: var(--color-contrast-text);
}

.inputs-wrapper .nps-score-input-label {
  text-align: center;
  font-weight: bolder;
}

.nps-score-input input {
  padding: unset;
  height: var(--input-height);
  aspect-ratio: 1/1;
  font-size: calc(var(--input-height) * 0.35);
  text-align: center;
}

.nps-score-input input:valid + fieldset {
  border-width: 1px;
}
.nps-score-input input:valid:focus + fieldset {
  border-width: 2px;
}
.nps-score-input:hover .MuiOutlinedInput-notchedOutline {
  border-width: 2px;
}

.nps-score-input[data-nps-type='promoter'] input:valid + fieldset,
.nps-score-input[data-nps-type='promoter'] input:valid:focus + fieldset,
.nps-score-input[data-nps-type='promoter']:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-promoter);
}

.nps-score-input[data-nps-type='passive'] input:valid + fieldset,
.nps-score-input[data-nps-type='passive'] input:valid:focus + fieldset,
.nps-score-input[data-nps-type='passive']:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-passive);
}

.nps-score-input[data-nps-type='detractor'] input:valid + fieldset,
.nps-score-input[data-nps-type='detractor'] input:valid:focus + fieldset,
.nps-score-input[data-nps-type='detractor']:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-detractor);
}

.explanations-wrapper {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  flex-direction: column;
}

.explanation-row {
  display: flex;
  justify-content: space-between;
}

.explanation-value {
  word-break: break-all;
}

.nps-color[data-nps-type='detractor'] {
  color: var(--color-detractor);
}

.nps-color[data-nps-type='passive'] {
  color: var(--color-passive);
}

.nps-color[data-nps-type='promoter'] {
  color: var(--color-promoter);
}
