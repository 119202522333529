.grid {
  display: grid !important;
}

:root {
  --flash-color: rgba(245, 212, 95, 0.3);
}

@keyframes flash {
  0% {
    background-color: none;
    border-radius: 8px;
  }
  50% {
    background-color: var(--flash-color);
  }
  100% {
    background-color: none;
    border-radius: 8px;
  }
}

.flash {
  animation-name: flash;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
